
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { useRole, useCreateRole, useUpdateRole } from '@/composables/api';
import { CREATED_SUCCESSFULLY_TEXT, ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/apis';
import { PartialRole } from '@/interfaces/Role';
import useFormErrors from '@/utils/validate';
import PermissionsTree from '../components/PermissionsTree.vue';
import { Rule } from 'async-validator';

const DEFAULT_FORM_VALUES: PartialRole = {
  name: '',
  permissions: []
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PermissionsTree
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const roleId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { data } = useRole({ roleId }, { enabled: isEdit.value });
    const checkedPermissions = ref([]);
    const defaultExpandedKeys = ref([]);
    const defaultCheckedKeys = ref([]);

    watch(data, ({ data }) => {
      formValues.value = {
        name: data.name,
        permissions: data.permissions
      };
      const permissions = data.permissions;
      defaultExpandedKeys.value = permissions;
      defaultCheckedKeys.value = permissions;
    });

    const getCheckedKeys = (checkedKeys: string[]) => {
      checkedPermissions.value = checkedKeys;
    };

    const { isLoading: isCreating, mutate: create } = useCreateRole();
    const { isLoading: isUpdating, mutate: update } = useUpdateRole();
    const { formErrors, bindFormItemError } = useFormErrors();

    const REQUIRED_RULE: Rule = {
      required: true,
      message: '此為必填欄位'
    };

    const rules = {
      name: [REQUIRED_RULE],
      permissions: [
        {
          required: false,
          trigger: 'change'
        }
      ]
    };

    watch(checkedPermissions, (value) => {
      formValues.value.permissions = value;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);
      console.log('formValues', formValues.value);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            formValues.value = {
              name: formValues.value.name,
              permissions: checkedPermissions.value
            };
            update({
              roleId,
              data: formValues.value
            }, {
              onSuccess() {
                ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                router.replace({
                  name: 'list_roles'
                });
              },

              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.error.message);
                formErrors.value = error.response?.data.error.message;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

              formValues.value = DEFAULT_FORM_VALUES;
              router.replace({
                name: 'list_roles'
              });
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.error.message);
              formErrors.value = error.response?.data.error.message;
            }
          });
        }
      });
    };

    return {
      data,
      formValues,
      formRef,
      rules,
      isCreating,
      isUpdating,
      submitForm,
      formErrors,
      bindFormItemError,
      defaultExpandedKeys,
      defaultCheckedKeys,
      getCheckedKeys
    };
  }
});
